<template>
    <v-dialog v-model="exibirModalEdicaoProduto" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Produto
                    </span>
                    <span style="width:5%;">
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">

                        <v-flex xs12>
                            <!-- <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="onFileChange" /> -->
                            <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="uploadCompressor" />
                            <v-avatar slot="offset" class="mx-auto d-block" size="130">
                            <v-icon v-if="!imgSrc" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
<!--                            <img v-if="imgSrc" :src="'data:' + imgTipo + ';base64,' + imgSrc" class="imgUpload" />-->
                            <img v-if="imgSrc" :src="imgSrc" class="imgUpload" />
                            </v-avatar>
                            <div style="display:flex;justify-content:center;margin-top:20px;">
                                <label for="file-upload" >
                                    <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                                    Foto
                                    </div>
                                </label>
                            </div>

                        </v-flex>

                        <div style="padding-bottom:10px;">
                            <h3>Nome Real <span style="font-size:14px;font-weight:500;">(Não é Permitido Alterar este campo)</span></h3>
                            <input type="text" disabled data-cy="input-nome" v-model="produto.descproduto" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>
                        <div style="padding-bottom:10px;">
                            <h3>Nome Exibido no Cardápio <span style="color:red;">(Máx. 255 Cararcteres)</span> </h3>
                            <input type="text" maxlength="50" data-cy="input-desc" v-model="produto.nomecd" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Descrição do Produto <span style="color:red;">(Máx. 255 Cararcteres)</span> </h3>
                              <v-textarea outlined color="" placeholder=""
                                      v-model="produto.descricao">
                             </v-textarea>
                            <!-- <tex type="text" maxlength="50" v-model="produto.nomecd" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" /> -->
                        </div>

                        <!-- <div style="padding-bottom:10px;">
                            <h3>Tempo de Preparo em minutos</h3>
                            <input type="number" v-model="subcategoria.tempopreparo" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>
                        <div style="padding-bottom:10px;">

                            <h3>Disponibilidade</h3>

                            <div style="display:flex;align-items:flex-end;">
                                <datetime class="inputsHorario" value-zone="UTC-3" :input-style="{color:'black'}" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="subcategoria.horainicio" >
                                </datetime>
                                <span style="margin-right:10px;" >até</span>
                                <datetime class="inputsHorario" value-zone="UTC-3" :input-style="{color:'black'}" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="subcategoria.horafinal" >
                                </datetime>
                            </div>

                        </div>
                        -->
                        <!-- <div style="padding-bottom:10px;display:flex;align-items:center;">
                            <v-switch v-model="produto.ativo" data-cy="switch-ativo"> </v-switch>
                            <label>{{produto.ativo ? 'Ativado': 'Pausado'}}</label>
                        </div>  -->

                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:flex-end;align-items:center; gap: 1rem">

                        <ModalOpcionaisProdutos
                            :idproduto="produto.idproduto"
                        />

                        <v-btn color="primary" @click="atualizarProduto()" data-cy="atualizarProduto">
                            SALVAR
                        </v-btn>
                    </div>
                </v-card>



            </v-card-text>


        </v-card>
    </v-dialog>
</template>

<script>
import Compressor from 'compressorjs';
// import dayjs from 'dayjs'
// import {serializarImagem} from '../../../services/serializar'
import ModalOpcionaisProdutos from './ModalOpcionaisProdutos.vue'

  export default {
    name: 'ModalEditarProduto',
    props: ["exibirModalEdicaoProduto", "produto"],
    data: () => ({
        nome: null,
        tempo: null,
        horarioinicial: false,
        horariofinal: false,
        subcategoria: null,
        imgSrc: null,



      img: "",
      scale: 100,
      quality: 10,
      originalSize: true,

      original: {},
      compressed: {},


      imageToCompress: {}
    }),
    mounted() {
        // this.getProdutosCD()
        this.$http('pdv/getImgProduto/' + this.produto.idproduto).then(resp => {
            this.imgSrc = resp.data[0].img;
        }).catch(error => {
            alert(error);
        });
    },
    methods: {
      async uploadCompressor(e) {
        e.preventDefault();

        this.imgSrc = URL.createObjectURL(e.target.files[0])

        const file = e.target.files[0];
        const produto = this.produto
        const _self = this;

        new Compressor(file, {
          quality: 0.6,
          mimeType: "image/jpeg",
          async success(result) {
            const imagem = new File([result], "name");

            const infoProduto = {
              idproduto: _self.produto.idproduto,
              nomecd: _self.produto.nomecd,
              link: _self.$dados.BUILD_API_URL
            };

            const formData = new FormData();
            formData.append('imagem', imagem, `${produto.idproduto}.jpg`);
            formData.append('infos', JSON.stringify(infoProduto));

            const headers = {
              'Content-Type': 'multipart/form-data'
            }

            _self.$http.post("pdv/uploadImagemProduto", formData, headers).then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error.response);
            });
          },
          error(err) {
            console.log("Erro no comprimir")
            console.log(err.message);
          },
        });
      },
        async salvaimg(tipoImg) {
            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }
            let dados = {
                "tipo": tipoImg,
                "nome": "imgProduto",
                "dados": this.imgSrc,
                "tabela": "produto",
                "campo": "img",
                "chave": "idproduto",
                "valor": this.produto.idproduto
            };
            await this.$http.post("pdv/imagemupdate", dados, { timeout: 60000 })
                .then((response) => {
                if (response.status == 200) {
                    this.imgSrc = response.data.result.imagem[0];
                }
                else {
                    console.log(JSON.stringify(response));
                }
            })
                .catch((error) => {
                console.log(error.response);
            });
        },
        onFileChange(e) {
          console.log("chamou  na edicao")
            // this.setImage(e)

            // e.target.files[0].name = this.produto.idproduto + '.jpg'
            this.imgSrc = e.target.files[0]
            // console.log(e.target.files[0])

            this.imageToCompress = e.target.files[0]


            // const headers = {
            //   'Content-Type': 'multipart/form-data'
            // }

            // const infoProduto = {
            //   idproduto: this.produto.idproduto,
            //   nomecd: this.produto.nomecd,
            //   ativo: this.produto.ativo,
            //   descricao: this.produto.descricao,
            // }


            // const formData = new FormData()

            // formData.append('file', this.imgSrc, this.produto.idproduto + '.jpg')
            // formData.append('infos', JSON.stringify(infoProduto))

            // this.$http.post("pdv/uploadImagem", formData, headers).then((response) => {
            //   console.log(response)
            // });


            // serializarImagem(e, 0.5).then(resp =>{
            //     this.imgSrc = resp.dados
            //     this.salvaimg(resp.tipo);
            // }).catch(error =>{
            //     this.loading = false
            //     this.snack = true
            //     this.snackColor = "error"
            //     this.snackText = error.message
            // })
        },
        direcionar() {
            this.$router.push(this.url);
        },
        fechar() {
            this.$emit('fechar');
        },
        getProdutosCD() {
            this.listaProdutosCD = [];
            this.$http('pdv/getProdutos/' + this.idcategoriacd + '/' + this.idsubcategoriacd).then((resp) => {
                this.listaProdutosCD = resp.data;
            }).catch(error => {
                console.log(error);
                this.$alertar("warning", "Não foi possivel exibir os Produtos", "=(");
            });
        },
        validarDados(obj) {
            if (!obj.idproduto) {
                this.$alertar("warning", "Produto não Selecionado", "=(");
                return false;
            }
            if (!obj.nomecd) {
                this.$alertar("warning", "Nome não Informado!", "=(");
                return false;
            }
            return true;
        },
        atualizarProduto() {
            let obj = {
                idproduto: this.produto.idproduto,
                nomecd: this.produto.nomecd,
                ativo: this.produto.ativo,
                descricao: this.produto.descricao,
            };
            if (this.validarDados(obj)) {
                this.$http.put(`pdv/produto`, obj).then(() => {
                    this.$alertar("success", "Produto Atualizado Com sucesso!", "=)");
                    // this.$emit('atualizarLista')
                    this.$emit('fechar');
                }).catch((error) => {
                    this.$alertar("warning", "Não foi possivel atualizar o Produto", "=(");
                    console.log(error);
                });
            }
        }
    },
    components: { ModalOpcionaisProdutos }
}
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

