<template>
  <div class="estiloColuna">
    <v-card  elevation="2" outlined  style="height: fit-content" >
      <v-card-text data-v-step-etapas="3">


        <div class="topoColuna">
              <div style="width:70%;display:flex;">
                  <Subtitulo :subtitulo="`Pedido ${pedido.numpedido}`" :corTexto="'#000'" />
                  <span style="color:#000;margin-left:10px;"> # Feito as {{pedido.datafeito | formatarHoraMinuto}} - Recebido as {{pedido.datarecebido | formatarHoraMinuto}}</span>
              </div>
              <div style="width:30%;display:flex;justify-content:flex-end;">
                  <ModalTransferenciaDono
                      :pedido="pedido"
                      @listarComandas="() => listarComandas()"
                      data-v-step-etapas="4"
                  />
                  <v-btn small elevation="" color="info" style="margin-right:20px;" @click="setexibirModalAssinatura()" >Ver assinatura</v-btn>
                  <v-btn data-v-step-etapas="5" small elevation="" color="info" @click="imprimirComanda()">Imprimir</v-btn>
                  <BotaoTutorial style="margin-left: 20px;" :categoria="categoria" :legenda="legenda" :urlVideo="urlVideo" />
              </div>

        </div>

        <div style="display:flex;">
          <div v-if="tipointegracao != 'HITS'" style="padding-left:15px; padding-top:5px;"><b>Hóspede</b>: {{pedido.nome}} {{pedido.sobrenome}} <b>{{` [${pedido.passante?'Passante':'Hospede'}] `}}</b></div>
          <div v-else style="padding-left:15px; padding-top:5px;"><b>Hóspede</b>: {{pedido.nome}} {{pedido.sobrenome}} <b>{{` [${pedido.passante?'Passante':(pedido.id_tipo_hospede_hits == 2? 'Day Use': (pedido.id_tipo_hospede_hits == 3?'Eventos':'Hospede') )}] `}}</b></div>
          <div style="padding-left:15px; padding-top:5px;margin-right:20px;">
            <b>Garçom Atual</b>:
            <a v-if="pedido && pedido.nomegarcom && pedido.idstatuscomanda != 5 && pedido.idstatuscomanda != 6 && pedido.idstatuscomanda != 7 " @click="setModalTrocarGarcom()" style="text-decoration:underline;font-weight:bold;"  >{{pedido.nomegarcom.toUpperCase()}} </a>
            <span v-else>
              <span v-if="pedido && pedido.nomegarcom" >{{pedido.nomegarcom.toUpperCase()}}</span>
            </span>
          </div>
          <div v-if="pedido && pedido.nomegarcominicial" style="padding-left:15px; padding-top:5px;">
            <b>Garçom Inicial</b>: {{ pedido.nomegarcominicial.toUpperCase() }}
          </div>
        </div>
        <div style="height:60px!important;width:100%;padding:10px;display:flex;justify-content:space-between;font-size:15px;">

            <div style="border:1px solid rgb(156, 156, 156);width:46%;border-radius:5px;height:45px;display:flex;align-items:center;justify-content:flex-start;padding:10px;">
               Local<span style="margin-left:10px;"><b>{{`${this.localComanda(pedido.idtipolocal)}` }}</b></span>
               &nbsp;&nbsp;-<span style="margin-left:10px;"><b> {{pedido.numerolocal}}</b></span>
            </div>

            <div style="border:1px solid rgb(156, 156, 156);width:23%;border-radius:5px;height:45px;display:flex;align-items:center;justify-content:flex-start;padding:10px;">
              <v-icon style="margin-right:10px;">mdi-book-account</v-icon>
              Reserva: <span style="margin-left:10px;"><b>{{pedido.numreserva}}</b></span>
            </div>

            <div style="border:1px solid rgb(156, 156, 156);width:23%;border-radius:5px;height:45px;display:flex;align-items:center;justify-content:flex-start;padding:10px;">
              <v-icon style="margin-right:10px;">mdi-home</v-icon>
              UH: <span style="margin-left:10px;"><b>{{pedido.coduh}}</b></span>
            </div>


        </div>

        <div
        v-if="tela == 'PENDENTE' && pedido.desconto"
        style="height:60px!important;width:100%;padding:10px;display:flex;font-size:15px; gap: 30px">

          <div
            style="border:1px solid rgb(156, 156, 156);border-radius:5px;height:45px;display:flex;align-items:center;justify-content:flex-start;padding:10px;"
          >
            <v-icon style="margin-right:10px;">mdi-map-marker</v-icon>
            Desconto
            <span style="margin-left:10px;">
              <b v-if="pedido.desconto && pedido.desconto.tipo && pedido.desconto.tipo == 'valor'">
                {{ pedido.desconto.valor  | formatarMoeda }}
              </b>
              <b v-else>
                {{ pedido.desconto.valor }}%
              </b>
            </span>
          </div>

          <div
            style="border:1px solid rgb(156, 156, 156);border-radius:5px;height:45px;display:flex;align-items:center;justify-content:flex-start;padding:10px;"
          >
            <v-icon style="margin-right:10px;">mdi-map-marker</v-icon>
            Total Desc:
            <span style="margin-left:10px;">
              <b v-show="pedido.desconto && pedido.desconto.valorComDesconto">
                {{ pedido.desconto.valorComDesconto | formatarMoeda }}
              </b>
            </span>
          </div>

        </div>

      </v-card-text>
    </v-card>

<!--    <v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:73%;max-height:73%;margin-top:1%;" >-->
    <v-card  elevation="2" outlined  style="padding-bottom:5px!important;margin-top:1%;" >
      <v-card-text>
        <!-- <div style="min-height:90%!important;display:flex;flex-direction:column;flex-wrap:wrap;"> -->


            <!-- <div style="margin:0px 10px;height:62vh;border:1px solid rgb(156, 156, 156);border-radius:5px;padding:10px;flex-direction:column;"> -->

                <div style="padding:5px;border-radius:2px;margin-bottom:10px;height:6vh!important;display:flex;justify-content:space-between;">
                    <div :style="(atrasado?'background-color:#ff0005;':'background-color:#1871c9;')+'width:70%;padding:0px;border-radius:2px;color:white;margin-bottom:10px;height:6vh!important;overflow:auto;'">
                    <!-- Pedido Atrasado a 15 min2; -->
                    <Countdown v-if="!atrasado && tempoentregar" height="10px;" style="font-size:15px!important;" :end="tempoentregar" data-v-step-etapas="6"></Countdown>
                    <div v-if="atrasado" style="witdh:100%;display:flex;height:6vh!important;font-weight:bold;padding-left:10px;align-items:center;">Atrasado</div>
                    </div>
                    <div style="border:1px solid rgb(156, 156, 156);border-radius:5px;width:30%;padding:5px;border-radius:2px;margin-bottom:10px;height:6vh!important;display:flex;justify-content:flex-end;align-items:center;font-weight:bold;padding-right:45px;">
                      Total: {{pedido.valorTotal | formatarMoeda}}
                      <!-- {{tempoentregar}} -->
                    </div>

                </div>

<!--                <div style="height:42vh;max-height:42vh;overflow:auto;margin-top:20px;">-->
<!--                <div style="min-height: 18em; overflow:auto;margin-top:20px;">-->
                  <div class="todos-pedidos">

                  <div v-for="pedidoitem in pedido.listadepedidos" :key="pedidoitem.idcomandaproduto"
                    style="width:95%;display:flex;flex-direction:column;padding-left:10px;padding-right:10px;height:30px;align-items:center;margin-bottom:60px;">

                    <div style="width:100%;display:flex;font-weight:bold;" v-show="pedidoitem.ativo == true"  data-v-step-etapas="7">

                      <ModalTransferenciaItemComanda
                          :pedido="pedido"
                          :pedidoitem="pedidoitem"
                          @listarComanda="() => listarComandas()"
                      />

                      <div style="width:5%;">{{pedidoitem.quantidade}} x</div>
                      <div :style="'width:65%;'+(pedidoitem.marcado_como_entregue && (tela != 'BAIXA') ? 'color:#d1b6b6;text-decoration:line-through;':'')">
                         <span v-if="pedidoitem.meioprato" style="font-weight:bold;font-size:16px;color:blue;">(MEIO PRATO)</span> {{pedidoitem.descproduto }} ({{pedidoitem.nomedepartamento}})

                         <span v-if="pedidoitem.flbebida" :style="pedidoitem.marcado_como_entregue?'color:#d1b6b6;':'color:blue;'">{{ pedidoitem.flbebidaantes ? '[ENTREGAR ANTES]': '[ENTREGAR JUNTO]' }}</span>
                            <div v-if="pedidoitem.observacao" style="width:100%;display:flex;font-size:14px;margin-left:0%;font-weight:500;">
                              Obs: {{pedidoitem.observacao}}
                            </div>
                            <div 
                              v-if="pedidoitem.opcional_escolhido" 
                              style="
                                width:100%;
                                display:flex;
                                font-size:14px;
                                margin-left:0%;
                                font-weight:500;
                                color: #d24623;
                              "
                            >
                              Escolhas: {{pedidoitem.opcional_escolhido.map((item, index) => `${index + 1} - ${item.descricao}`).join(', ')}}
                            </div>
                        <div style="width:100%;display:flex;flex-direction:row;justify-content:space-between;" v-if="((perfil=='BAR' && pedidoitem.flbebida) || perfil != 'BAR')" >
                            <div>
                                <a v-if="$store.getters.get_idperfil !=5 || garcomAutorizado "  @click="cancelarItemComanda(pedidoitem)" data-v-step-etapas="9" style="color:red;text-decoration:underline;">Remover</a>
                            </div>



                            <div v-if="tela != 'PENDENTE'">
                              <div v-if="!pedidoitem.marcado_como_entregue">
                                  <a v-if="$store.getters.get_idperfil != 5" @click="marcarItemComoEntregue({pedidoitem:pedidoitem,pedido:pedido})" data-v-step-etapas="10" style="color:blue;text-decoration:underline;">Pronto</a>
                              </div>
                          </div>

                        </div>
                      </div>
                      <div v-if="pedidoitem.exibirtaxa == 'true'" style="width:20%;display:flex;justify-content:flex-end;align-items:flex-end;flex-direction:column;">

                          <div>{{(pedidoitem.quantidade * pedidoitem.precounitario)| formatarMoeda}}</div>
                          <div v-if="pedidoitem.valortaxa">Taxas : {{pedidoitem.valortaxa}}%</div>
                          <div v-else>(sem taxas)</div>
                          <div style="color:red;" v-if="pedidoitem.desconto_auto_percentual > 0">Desconto Categoria: {{pedidoitem.desconto_auto_percentual}}%</div>
                      </div>
                      <div v-else style="width:20%;display:flex;justify-content:flex-end;">
                          {{(pedidoitem.quantidade * pedidoitem.precounitario)| formatarMoeda}}
                      </div>
                    </div>



                  </div>


                </div>
                <div style="width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:flex-end;">

                      <!-- <div v-if="pedido.passante && (pedido.idstatuscomanda == 9 || (pedido.idstatuscomanda == 5 && garcomPodeFecharPassante === false))" style="background color:red;width:80%;display:flex;flex-direction:row;">

                          <div style="width:25%;padding:20px;">
                              <v-select return-object color v-model="cobrartaxas" :items="listacobrartaxas" @change="setValorFinalFechar()"  item-text="nome" item-value="id" label="Cobrar Taxas"></v-select>
                          </div>

                          <div style="width:25%;padding:20px;">
                              <v-select color v-model="tipoPagamentoSelecionado" :items="listaTiposPagamentos" item-text="label" item-value="value" label="Tipo Pagamento"></v-select>
                          </div>

                          <div style="width:25%;padding:20px;">
                              <v-text-field type="number" name="name" label="NSU" id="nsu" v-model="nsu" ></v-text-field>
                          </div>

                          <div style="display:flex;align-items:center;width:25%;font-size:18px;">
                               <b style="">Valor Final:</b>{{valorFecharComanda | formatarMoeda}}
                          </div>

                      </div> -->
                      <!-- <v-btn v-if="(tela != 'GARCOM' || pedido.idstatuscomanda == 9)" small elevation="" color="primary"   style="width:20%;height:7vh"  :disabled="botaoSimHabilitado" data-cy="btn-sim" @click="sim()">{{textosim}}</v-btn>
                      <v-btn v-if="(tela != 'GARCOM' || pedido.idstatuscomanda == 9)" small elevation="" color="primary"   style="width:40%;height:7vh"
                        :disabled="botaoSimHabilitado" data-cy="btn-sim" @click="setexibirModalAddPagtoBaixar()">
                        Adicionar Pagamento e Baixar
                        </v-btn> -->


                  <div v-if="tela != 'PENDENTE'">
                    <v-btn
                    v-if="
                      (((tela == 'BAIXA' || tela == 'PENDENTE') && pedido.passante === false)||
                      (tela === 'GARCOM' && pedido.passante === false && pedido.idstatuscomanda == 9)||
                      (tela === 'GARCOM' && pedido.passante === true && pedido.idstatuscomanda == 9 && garcomPodeFecharPassante === false)||
                      (tela != 'BAIXA' && tela != 'PENDENTE' && tela != 'GARCOM')
                      )
                    " small elevation="" color="primary" style="height:7vh;padding: 0 70px" data-v-step-etapas="11"  :disabled="botaoSimHabilitado" data-cy="btn-sim" @click="sim()">{{textosim}}</v-btn>

                    <v-btn v-if="(
                            ((tela == 'BAIXA'  || tela == 'PENDENTE') && pedido.passante === true)||
                            (tela == 'GARCOM' && pedido.passante === true && pedido.idstatuscomanda == 9 && garcomPodeFecharPassante === true)
                            )" small
                        elevation="" color="primary"  style="height:7vh;padding: 0 100px"
                          :disabled="botaoSimHabilitado" data-cy="btn-sim" @click="setexibirModalAddPagtoBaixar()">
                          Adicionar Pagamento e Baixar
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      small
                      elevation=""
                      color="success"
                      style="height:7vh;padding: 0 10px; margin-right: 10px;width:120px;"
                      @click="() => setToggle()"
                      v-show="(tipoIntegracao === 'HITS' && descontoFinalComanda) ||  (tipoIntegracao === 'ADMH' && !pedido.passante)"
                    >
                      Add desconto
                    </v-btn>

                    <v-btn
                      small
                      elevation=""
                      color="warning"
                      style="height:7vh;padding: 0 70px; margin-right: 10px;width:150px;"
                      @click="() => exibirModalLancamentoConsumo = true"
                    >
                      Add consumo
                    </v-btn>

                    <v-btn v-if="pedido.passante === true"
                      small
                      elevation=""
                      color="primary"
                      style="height:7vh;padding: 0 70px;width:250px;"
                      data-cy="btn-sim"
                      :disabled="botaoSimHabilitado"
                      @click="setexibirModalAddPagtoBaixar()"
                    >
                      Add Pagto e fechar comanda
                    </v-btn>

                    <v-btn v-else
                      small
                      elevation="" color="primary"
                      style="height:7vh;padding: 0 30px"
                      :disabled="botaoSimHabilitado" data-cy="btn-sim"
                      @click="sim()"
                    >{{textosim}}
                    </v-btn>
                  </div>
                </div>




            <!-- </div> -->




        <!-- </div> -->

      </v-card-text>


  </v-card>

    <ModalAssinatura v-if="exibirModalAssinatura" :exibirModalAssinatura="exibirModalAssinatura"
            :pedido="pedido"
            @fechar="()=>setexibirModalAssinatura()" />

    <ModalAddPagtoPassante v-if="exibirModalAddPagtoBaixar" :exibirModalAddPagtoBaixar="exibirModalAddPagtoBaixar"
            :pedido="pedido"
            @listarComandas="() =>listarComandas()"
            @fechar="()=>setexibirModalAddPagtoBaixar()" />


    <ModalAddDesconto
      v-if="exbirModalAdicionarDesconto" :exbirModalAdicionarDesconto="exbirModalAdicionarDesconto"
      :pedido="pedido"
      @listarComandas="() => listarComandas()"
      @fechar="() => setToggle()"
      :integracao="tipoIntegracao"
    />

    <ModalConfirmacao :showModal="exibirModalConfirmarCancelar" :titulo="'Confirmar cancelamento do item: '+itemSelecionadoParaRemocao.descproduto" :exibeBtnCancelar="false"
        :textosim="'Sim'"       @sim="()=>confirmarCancelamento()"
        :textonao="'Não'"       @nao="()=>setModalConfirmarCancelar(false)"
        tipoTela="REMOCAOITEM"
        :motivoremocao="motivoremocao"
        @motivoremocaoHandleUpdate="updateMotivorRemocao"
      />



    <v-dialog v-model="modalTrocaGarcom" width="60vw" max-width="60vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">

            <v-toolbar color="blue" class="">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Troca de Garçom
                    </span>
                    <span style="width:5%;">
                        <v-btn x-small color="red" @click="modalTrocaGarcom= false" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text style="">

                <v-card style="" v-if="pedido && pedido.nomegarcom">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="3" style="font-weight:bold;">Garçom Atual:</v-col>
                        <v-col cols="12" md="3">{{pedido.nomegarcom.toUpperCase()}}</v-col>
                        <v-col cols="12" md="3" style="font-weight:bold;"></v-col>
                        <v-col cols="12" md="3"></v-col>
                      </v-row>
                    </v-card-text>
                </v-card>

                <v-card style="margin-top:10px;">
                    <v-card-text>
                            <h3>Garçons Disponíveis Agora</h3>
                            <v-data-table data-table-dense-header-height='100px' :headers="headersDeGarconsDisponiveis" :items="listaDeGarconsDisponiveis" :items-per-page="20" style="width:100%;margin-top:20px;" >
                                <template v-slot:[`item.acao`]="{ item }">
                                    <v-btn v-if="item.idusuario" small elevation="" color="info" @click="setModalConfirmacaoTrocaGarcom(item)" >
                                         Trocar
                                    </v-btn>
                                </template>
                            </v-data-table>

                    </v-card-text>
                </v-card>

            </v-card-text>

          </div>
    </v-dialog>


    <ModalConfirmacao :showModal="exibirModalConfirmacaoTrocaGarcom" v-if="this.pedido && this.garcomNovo" :titulo="`Confirmar Troca de Garçom: De:${this.pedido.nomegarcom}  Para: ${this.garcomNovo.nomegarcom}`"
        :exibeBtnCancelar="true"
        :textosim="'Sim'"       @sim="()=>realizarTrocaGarcom({pedido:this.pedido,garcomnovo:this.garcomNovo})"
        :textonao="'-'"
        :textocancelar="'Cancelar'" @cancelar="()=>{this.exibirModalConfirmacaoTrocaGarcom = false}" />


    <ModalConfirmacao
      v-if="this.pedido"
      titulo="Qualquer consumo lançado na comanda a partir desta funcionalidade já será registrado como Entregue! Deseja prosseguir?"
      :showModal="exibirModalLancamentoConsumo"
      :exibeBtnCancelar="true"
      textosim="Continuar"
      :textonao="'-'"
      :textocancelar="'Cancelar'"
      @sim="adicionarConsumo()"
      @cancelar="()=>{this.exibirModalLancamentoConsumo = false}"
    />



  </div>
</template>

<script>
  import ModalTransferenciaItemComanda from "@/components/layout/Modais/ModalTransferenciaItemComanda.vue";
  import ModalTransferenciaDono from "@/components/layout/Modais/ModalTransferenciaDono.vue";

  const dayjs = require('dayjs')
  import Subtitulo from '../../components/layout/Subtitulo.vue'
  // import PedidosListaAdm from '../../components/pedidos/PedidosListaAdm.vue'
  import Countdown from 'vuejs-countdown'

  import ModalAssinatura from '../layout/Modais/ModalAssinatura.vue'
  import ModalAddPagtoPassante from '../layout/Modais/ModalAddPagtoPassante.vue'
  import ModalAddDesconto from '../layout/Modais/ModalAddDesconto.vue'
  import ModalConfirmacao from '../layout/ModalConfirmacao.vue'
  import BotaoTutorial from '../layout/BotaoTutorial.vue'



  export default {
      name: 'ColunaPedido',
      computed: {
        exibirModalBaixaPagmento(){
          return this.exibirModalAddPagtoBaixar
        },
        exibirModalAdicionarDesconto(){
          return this.exbirModalAdicionarDesconto
        }
      },
      components: {
        ModalTransferenciaItemComanda,
        ModalTransferenciaDono,
        //PedidosListaAdm,
        Subtitulo,
        Countdown,
        ModalAssinatura,
        ModalAddPagtoPassante,
        ModalConfirmacao,
        BotaoTutorial,
        ModalAddDesconto,
      },
    props:["url","pedido",'exibirnao','textonao','textosim','perfil', 'urlVideo', 'categoria', 'legenda','tela'],
    beforeUnmount(){
      clearInterval(this.intervalochecktimer)
    },

    async mounted(){



      this.nometela = this.tela

      await this.buscarDados();

      await this.verificarDescontoFinalComanda()
      // this.ojbPedidosEnviados = await this.getComandasReservaPorStatus(0,2)
      await this.listarTiposPagamentos()
      await this.setValorFinalFechar()
      // await this.verificarProdutosEmProducao()

      this.tipointegracao = this.$dados.BUILD_TIPO_INTEGRACAO

      this.tipoIntegracao = localStorage.getItem('tipo_integracao')

      // await this.$http.post('pdv/buscarparametros',{chave:'TIPO_INTEGRACAO'})
      //                 .then(resp =>{
      //                     this.tipointegracao = resp.data[0].valor
      //                 }).catch(error =>{
      //                     alert(error)
      //                 })


      await this.$http.post('pdv/buscarparametros',{chave:'FLUXOPEDIDOS'})
            .then(resp =>{
                let fechamentoAutomatico = JSON.parse(resp.data[0].valor6);
                if (fechamentoAutomatico) {
                    this.garcomPodeFecharPassante = fechamentoAutomatico.permitirFechamentoPassanteGarcom
                }
            }).catch(error =>{
                alert(error)
            })

      this.tempoentregar = null
        if(this.pedido){

          if(this.pedido.tempocategoria > this.pedido.temposubcategoria){
            this.tempoemminutos = this.pedido.tempocategoria
          }else{
            this.tempoemminutos = this.pedido.temposubcategoria
          }

          if(!this.tempoemminutos){
            this.tempoemminutos = 40
          }

          this.tempoentregar = dayjs(this.pedido.datafeito).add(this.tempoemminutos,'minute')
          this.diaentrega = dayjs(this.tempoentregar).format()
          this.tempoentregar = dayjs(this.tempoentregar).format('MMMM DD, YYYY, HH:mm:ss')


          this.diaatual = dayjs().format()
          this.diaatual = new Date(this.diaatual).getTime()
          this.diaentrega = new Date(this.diaentrega).getTime()

          if(this.diaatual > this.diaentrega){
            this.atrasado = true
          }


          const self = this
          this.intervalochecktimer = setInterval(function(){

                                                    self.diaatual = dayjs().format()
                                                    self.diaatual = new Date(self.diaatual).getTime()
                                                    self.diaentrega = new Date(self.diaentrega).getTime()

                                                    if(self.diaatual > self.diaentrega){
                                                      self.atrasado = true
                                                    }

                                                    // self.atrasado = true

                                                }, 5000);



        }



    },
    watch:{
      pedido(valor){
        this.tempoentregar = null
        if(valor){

          if(valor.tempocategoria > valor.temposubcategoria){
            this.tempoemminutos = valor.tempocategoria
          }else{
            this.tempoemminutos = valor.temposubcategoria
          }

          if(!this.tempoemminutos){
            this.tempoemminutos = 40
          }

          this.tempoentregar = dayjs(this.pedido.datafeito).add(this.tempoemminutos,'minute')
          this.diaentrega = dayjs(this.tempoentregar).format()
          this.tempoentregar = dayjs(this.tempoentregar).format('MMMM DD, YYYY, HH:mm:ss')

          this.diaatual = dayjs().format()

          this.diaatual = new Date(this.diaatual).getTime()
          this.diaentrega = new Date(this.diaentrega).getTime()
          if(this.diaatual > this.diaentrega){
            this.atrasado = true
          }


        }
      }
    },
    data: () => ({
        exibirModalAddPagtoBaixar:false,
        exbirModalAdicionarDesconto:false,
        nometela:'',
        nsu: null,
        valordesconto:0,
        cobrartaxas:{nome:'SIM',id:1},
        listacobrartaxas:[{nome:'SIM',id:1},{nome:'NAO',id:2}],
        totalComanda:0,
        totalComandaSemTaxa:0,
        valorFecharComanda:null,
        tipoPagamentoSelecionado:null,
        listaTiposPagamentos:[],
        exibirModalConfirmacaoTrocaGarcom:false,
        garcomAntigo:null,
        garcomNovo:null,
        botaoSimHabilitado:false,
        ojbPedidosEnviados:null,
        diaatual:null,
        diaentrega:null,
        tempoentregar:null,
        tempoemminutos:null,
        atrasado:false,
        intervalochecktimer:null,
        exibirModalAssinatura:false,
        tipointegracao:null,
        exibirModalConfirmarCancelar:false,
        itemSelecionadoParaRemocao:{},
        localidades: [],
        motivoremocao: "",
        modalTrocaGarcom:false,
        headersDeGarconsDisponiveis:[
          { text: "ID", value: "idusuario", width:'10%'},
          { text: "Nome", value: "nomegarcom", width:'30%'},
          { text: "Praça", value: "praca", width:'15%'},
          { text: "Horario", value: "horario", width:'25%'},
          { text: "Ação", value: "acao", width:'20%'},
        ],
        listaDeGarconsDisponiveis:[{}],
        itensEmProducao:null,
        garcomAutorizado: JSON.parse(localStorage.getItem('config'))?.permitirExclusaoItemGarcom,
        garcomPodeFecharPassante:true,
        exibirModalLancamentoConsumo: false,
        tipoIntegracao: '',
        descontoFinalComanda: false,

        transferenciaComanda: {
          exibirModal: false,
          motivo: '',
          antigoDono: '',
          novoDono: '',
          idComanda: '',
          usuarioResponsavel: '',
        },
    }),
    methods:{
        exibirModalTransferenciaDonoComanda() {
          this.transferenciaComanda.exibirModal = true;
        },

        // exibirModalTransferenciaDonoComanda() {
        //   this.transferenciaComanda.motivo = '';
        // },

        listarPossiveisCheckins() {
          alert("Checkins");
        },

        transferirDonoComanda() {
          alert("Transferir");
        },
        async buscarDados() {
          this.$http("pdv/localidades/").then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.localidades = resp.data;
                }
            }).catch((error) => {
                this.$alertar("warning", error, "=(");
            });
        },
        localComanda(id) {
          if (this.localidades.length) {
            const local = this.localidades.filter((e) => Number(e.id_localidade) === Number(id));
            return local[0].descricao;
          }
        },

        listarComandas(){
          this.$emit('listarComandas')
        },
      // verificarProdutosEmProducao(){
      //       this.$http.post('/pdv/getProdutosComandaEmProducao',{idcomanda:this.pedido.numpedido}).then(resp =>{
      //           this.itensEmProducao = resp.data
      //       }).catch(error =>console.log('ERRO RETNORO ',error))
      // },

        async verificarDescontoFinalComanda() {
          this.$http.post('pdv/buscarparametros',{chave:'DESCONTO_FINAL_COMANDA'})
          .then(resp =>{
            if(resp.data && resp.data.length > 0){
              this.descontoFinalComanda = resp.data[0].valor === 'true' ? true : false
            }
          }).catch(err => {
            console.log(err)
          })
        },
        setValorFinalFechar(){

            if(this.cobrartaxas.id == 1){
              this.valorFecharComanda = this.pedido.valorTotal
            }else{
              this.valorFecharComanda = this.pedido.valorTotalSemTaxa
            }
        },
        listarTiposPagamentos(){

            this.$http.post('/pdv/getTiposPagamentos').then(resp =>{
                this.listaTiposPagamentos = resp.data.tipospagamentos
            }).catch(error =>console.log('ERRO RETNORO ',error))
        },
        marcarItemComoEntregue(objeto){
            if(this.pedido.listadepedidos.length == 1 && this.itensEmProducao == 0){
              this.sim()
            }else{
              this.$emit('marcarItemComoEntregue', objeto)
            }

        },
        realizarTrocaGarcom(dados){
          this.$http.post('pdv/trocarGarcomPedidosPendentes',dados).then(() =>{
              this.modalTrocaGarcom = false
              this.exibirModalConfirmacaoTrocaGarcom = false
              window.location.reload()
          }).catch(error => {
              console.log(error)
              this.modalTrocaGarcom = false
              this.exibirModalConfirmacaoTrocaGarcom = false
          })
        },
        async setModalTrocarGarcom(){
            this.listaDeGarconsDisponiveis = await this.$http.post('pdv/listarGarconsDisponiveisAgora').then((resp) =>{ return resp.data}).catch(error => console.log(error))
            this.modalTrocaGarcom = !this.modalTrocaGarcom
        },
        async setexibirModalAddPagtoBaixar(){
          this.exibirModalAddPagtoBaixar = !this.exibirModalAddPagtoBaixar
        },

        async setToggle(){
          this.exbirModalAdicionarDesconto = !this.exbirModalAdicionarDesconto
        },


        // exbirModalAdicionarDesconto: false,


        toggleModalDesconto(){


          console.log('TOGGLE DESCONTO')
          console.log(this.exbirModalAdicionarDesconto)

          this.exbirModalAdicionarDesconto = !this.exbirModalAdicionarDesconto

          console.log(this.exbirModalAdicionarDesconto)

        },
        setModalConfirmacaoTrocaGarcom(novoGarcom){
            this.garcomNovo = novoGarcom
            this.exibirModalConfirmacaoTrocaGarcom = !this.exibirModalConfirmacaoTrocaGarcom
        },
        setarPedido(){
          this.$emit('setarPedido', this.pedido)
          this.$emit('carregarPedidos')
          this.setModalConfirmarCancelar(false)
        },
        confirmarCancelamento(){

            this.$http.post("pdv/removerItemDaComanda",{dados:{...this.itemSelecionadoParaRemocao, motivoremocao:this.motivoremocao}})
            .then(()=>{

                let listaDeItensFiltrados = this.pedido.listadepedidos.filter(x =>{
                    return x.idcomandaproduto != this.itemSelecionadoParaRemocao.idcomandaproduto
                })

                this.pedido.listadepedidos = listaDeItensFiltrados
                console.log("pedido.listadepedidos>>>>", this.pedido.listadepedidos)
                console.log(1)
                if(this.pedido.listadepedidos.length == 0){
                  console.log(2)
                  //  window.location.reload()
                   this.sim()
                }else{
                  console.log(3)
                  this.setarPedido()
                }
            })
            .catch(error => {
              this.setModalConfirmarCancelar(false)
              this.$alertar("warning",error.response?.data?.message ? error.response.data.message : "Erro ao remover item da comanda","=(")
            })
        },
        setModalConfirmarCancelar(valor){
            this.exibirModalConfirmarCancelar = valor
        },
        cancelarItemComanda(item){

          this.itemSelecionadoParaRemocao = item
          this.exibirModalConfirmarCancelar = true
        },
        direcionar(){
            this.$router.push(this.url)
        },
        async getComandasReservaPorStatus(idreservasfront,idstatus){
            let objetoRetorno = {}
            await this.$http('pdv/getComandasReservaPorStatus/'+idreservasfront+'/'+idstatus+'/t').then((resp) =>{
              console.log(resp.data)
              objetoRetorno.valorTotal = 0
              objetoRetorno.valorTotalSemTaxa = 0
              objetoRetorno.listadepedidos = resp.data
              for(var i = 0, len = objetoRetorno.listadepedidos.length; i < len; i++){
                  objetoRetorno.valorTotal += objetoRetorno.listadepedidos[i].valor

                  // alert(objetoRetorno.listadepedidos[i].valorsemtaxa)
                  objetoRetorno.valorTotalSemTaxa += objetoRetorno.listadepedidos[i].valorsemtaxa
                  objetoRetorno.statuscomanda = objetoRetorno.listadepedidos[i].statuscomanda
              }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            return objetoRetorno
        },
        imprimirComanda(){
          this.$emit('imprimirComanda',this.pedido)
        },
        setexibirModalAssinatura(){
          // this.$alertar('warning','tes')
          this.exibirModalAssinatura = !this.exibirModalAssinatura
        },
        sim(){
          // if(this.pedido.passante && (this.tela == 'GARCOM' || this.tela == 'BAIXA')){
          //   if(!this.tipoPagamentoSelecionado){
          //     this.$alertar("warning","Informe um tipo de pagamento","=(")
          //     return
          //   }
          //   this.pedido.tipoPagamentoSelecionado = this.tipoPagamentoSelecionado
          //   this.pedido.cobrartaxas = this.cobrartaxas
          //   this.pedido.nsu = this.nsu
          // }

          this.botaoSimHabilitado=true
          this.$emit('sim',this.pedido)
        },
        nao(){
          this.$emit('nao',this.pedido)
        },
        updateMotivorRemocao(novoEstado){
          this.motivoremocao = novoEstado
        },
        adicionarConsumo(){
          const { idReservaFront, idHospede, idCheckin} = this.pedido
          const url = `/Adm/FazerPedido/${idReservaFront}/${idHospede}/${idCheckin}`
          this.$router.push({
            path: url,
            query: { feitoPorFecharComanda: true },
          })
        },
    }
  }
</script>

<style scoped>
    .estiloColuna{
        /* background-color: white; */
        /* padding-top:10px; */
        width: 70%!important;
        margin-right:2%!important;
        /* border:1px solid rgb(156, 156, 156);
        border-radius:5px; */

    }

    .todos-pedidos {
      margin-top:20px;
      min-height: 18em;
      overflow:auto;
    }

    @media (min-height: 600px) {
      .todos-pedidos {
        min-height: 28vh;
      }
    }

    @media (min-height: 650px) {
      .todos-pedidos {
        min-height: 30vh;
      }
    }

    @media (min-height: 750px) {
      .todos-pedidos {
        min-height: 34vh;
      }
    }

    @media (min-height: 800px) {
      .todos-pedidos {
        min-height: 38vh;
      }
    }

    @media (min-height: 830px) {
      .todos-pedidos {
        min-height: 40vh;
      }
    }

    .topoColuna{
      width:100%;
      height:10%;
      border:none;
      /* border-top-right-radius: 5px;
      border-top-left-radius: 5px; */
      /* background-color:#1976D2; */
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding-left:2px;
      padding-right:20px;
    }

    li::after {
      display: flex;
      align-items: center;
    }

</style>
