<template>
  <div class="ddpms">
    <span style="font-weight: bold; color: #036097"
      >ITENS DISPONÍVEIS PARSELEÇÃO</span
    >
    
    <v-card
      elevation="2"
      outlined
      style="padding-bottom: 0px !important; height: 19%; max-height: 110px; padding:2px;"
    >
    <!-- <BotaoTutorial style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;" categoria="14" legenda="" urlVideo="" /> -->
      <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
      <v-card-text class="" style="margin-top: 0px; margin-bottom: 0px">
        <div style="height: 70px; max-height: 70px; display: flex">
          <div style="width: 48%; margin-left: 0%">
            <label for="" style="display:flex;flex-direction:row;" >PDV
                <div id="qtdeDePdvsNaLista" v-if="listaPDVsPMS && listaPDVsPMS.length > 1">
                  (S)
                </div>

            </label>
            <select
              v-model="idpdvpms"
              data-cy="idpdvpms"
              class="select"
              @change="getCategoriasPMS()"
            >
              <option
                v-for="pdv in listaPDVsPMS"
                :key="pdv.idpdv"
                :value="pdv.idpdv"
              >
                {{ pdv.descpdv }}
              </option>
            </select>
          </div>
          <div style="width: 48%; margin-left: 2%">
            <label for="">Categorias</label>
            <select
              data-cy="idcategoriapms"
              v-model="idcategoriapms"
              class="select"
              @change="getProdutosPMS()"
              :disabled="idpdvpms === null"
            >
              <option
                v-for="categoria in listaCategoriasPMS"
                :key="categoria.idcategoriaprod"
                :value="categoria.idcategoriaprod"
              >
                {{ categoria.desccategoriaprod }}
              </option>
            </select>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      elevation="2"
      outlined
      class="mt-2"
      height="80.5%"
      style="overflow: auto; max-height: 65vh"
    >
      <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
      <v-card-text class="">
            <!-- <v-text-field outlined clearable label="Buscar itens" v-model="textoProdPesquisar" data-cy="textoProdPesquisar"> -->
              <v-text-field outlined  label="Buscar itens" v-model="produtoPesquisaDb" data-cy="produtoPesquisa" @input="() => {this.getProdutosPMS() 
                this.getTotalProdutosPMS()}">
              
            </v-text-field>
        <v-card
          v-for="prod in itensFiltrados"
          :key="prod.idpdvproduto"
          :style="prod.cardapios ? 'background-color:#d1deeb;' : ''"
          elevation="2"
          outlined
          class="mt-2"
          height="80.5%"
          data-cy="cardapio-pms"
        >
          <v-card-text>
            <div style="display: flex">
              <div style="width: 90%; color: #5a5858; font-weight: bold" data-cy="prod.descproduto">
                <div v-if="prod.nomedepartamento">
                  ({{prod.nomedepartamento}})
                </div>
                <div data-cy="prod.nomeproduto">
                  {{ prod.descproduto }} <v-spacer></v-spacer> {{ prod.precounitario | formatarMoeda }}
                </div>
              </div>
              <div style="width: 10%">
                <v-icon
                  color="info"
                  style="cursor: pointer"
                  large
                  @click="adicionarProduto(prod)"
                  data-cy="adicionarProduto"
                >
                  mdi-arrow-right-bold-box
                </v-icon>
              </div>
              <v-icon
                @click="
                  [(listaPdvAssoc = prod.cardapios), exibirPDVsAssociados()]
                "
                >mdi-eye</v-icon
              >
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <div style="background: white; width: 100%; padding: 10px;">
      <Pagination 
          :pagina="currentPage" 
          :total="totalItems" 
          :limit="itemsPerPage" 
          @change-page="getProdutosPMS"
        />
    </div>
    <v-dialog v-model="exibeListaPDVAssoc" max-width="600px">
      <v-card width="600px">
        <v-toolbar color="primary"><v-tooblar-title style="color: white;">PDVs Associados</v-tooblar-title>
        <v-spacer></v-spacer><v-icon color="red" @click="exibeListaPDVAssoc = false">mdi-close-circle</v-icon>
        </v-toolbar>
        
        <v-card-text>
          <v-row no-gutters class="ma-1">
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> PDV </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Categoria </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Sub Categoria </v-card>
            </v-col>
          </v-row>
          <div class="ma-1">

          <v-row no-gutters v-for="pdv in listaPdvAssoc" :key="pdv.id" >
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomepdv && pdv.nomepdv.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nome && pdv.nome.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomesub && pdv.nomesub.toUpperCase() }}
              </v-card>
            </v-col>
          </v-row>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!--
       <div class="ddpms">
       <select v-model="statuscomanda" class="select">
            <option value=2 >Pedido Solicitado</option>
            <option value=3 >Pedido em Produção</option>
            <option value=4 >Pedido Pronto</option>
            <option value=5 >Pedido Entregue</option>
            <option value=6 >Pedido Cancelado</option>
        </select>
     <div >
          tes
       
    </div>
    
  </div> -->
</template>

<script>
// import BotaoTutorial from '../layout/BotaoTutorial.vue';
import Pagination from '../Paginação/Pagination.vue';
export default {
    name: "Coluna PMS",
    props: ["url", "atualizar"],
    data: () => ({
        listaPDVsPMS: [],
        idpdvpms: null,
        listaCategoriasPMS: [],
        idcategoriapms: null,
        listaProdutosPMS: [],
        listaProdutosPMSFiltrados: [],
        currentPage: 0,
        itemsPerPage: 10,
        totalItems: 0,
        idprodutopms: null,
        idprodutoclicado: null,
        listaPdvAssoc: [],
        exibeListaPDVAssoc: false,
        produtoPesquisa: "",
        produtoPesquisaDb: "",
    }),
    components: {
      // BotaoTutorial,
      Pagination
    },
    computed: {
        itensFiltrados(){
            return this.listaProdutosPMS.filter(item => {
              if(this.produtoPesquisa.toLowerCase().trim() === '') {
                  return item.descproduto
              } else {
                  return item.descproduto.toLowerCase().includes(this.produtoPesquisa.toLowerCase())
              }                   
            })
        },
    },
    watch: {
        atualizar(valor) {
            console.log("dsdasdsaasa>>>>",valor);
            this.getProdutosPMS();
        },
        // textoProdPesquisar(valor) {
        //     if (valor.length > 0) {
        //         console.log("a 111");
        //         this.listaProdutosPMSFiltrados = this.listaProdutosPMS.filter(x => {
        //             if (x.descproduto.trim().toUpperCase().includes(valor.trim().toUpperCase())) {
        //                 return x;
        //             }
        //         });
        //     }
        //     else {
        //         this.listaProdutosPMSFiltrados = this.listaProdutosPMS;
        //     }
        // },
    },
    async mounted() {
        await this.getPDVSPMS();
        await this.getTotalProdutosPMS();
    },
    methods: {
        direcionar() {
            this.$router.push(this.url);
        },
        exibirPDVsAssociados() {
            this.exibeListaPDVAssoc = true;
        },
        adicionarProduto(produto) {
            this.idprodutoclicado = produto.idproduto;
            let obj = {
                idcategoriaprodcd: localStorage.getItem("idcatcd"),
                idsubcategoriaprodcd: localStorage.getItem("idsubcatcd"),
                idproduto: this.idprodutoclicado,
                idpdvproduto: produto.idpdvproduto
            };
            this.$http
                .post("pdv/addProdutoAoCD", obj)
                .then(() => {
                console.log("te");
                // this.getProdutosPMS()
                this.$emit("atualizardados");
            })
                .catch(() => {
                this.$alertar("warning", "Não foi Possível Adicionar o Produto ao Cardápio Digital!", "=(");
            });
        },
        getPDVSPMS() {
            this.$http("pdv/getPDVSPMS/0")
                .then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    // this.listaPDVsPMS = resp.data;
                    this.listaPDVsPMS = [
                      {
                        idpdv: null,
                        descpdv: "Todos"
                      },
                      ...resp.data
                    ]
                    this.idpdvpms = this.listaPDVsPMS[0].idpdv;
                    if(this.idpdvpms === null){
                      await this.getProdutosPMS();
                    } else {
                      await this.getCategoriasPMS();
                    }
                }
            })
                .catch((error) => {
                // this.$alertar('warning','Não foi possivel listar os PDVs do PMS','=(')
                this.$alertar("warning", error, "=(");
            });
        },
        getCategoriasPMS() {
            this.listaCategoriasPMS = [];
            if(this.idpdvpms === null){
              this.idpdvpms = null,
              this.idcategoriapms = null,
              this.getProdutosPMS()
              return;
            } 
            this.$http(`pdv/getCategoriasPMS/${this.idpdvpms}/0`)
                .then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.listaCategoriasPMS = resp.data;
                    this.idcategoriapms = this.listaCategoriasPMS[0].idcategoriaprod;
                    await this.getProdutosPMS();
                }
            })
                .catch(() => {
                this.$alertar("warning", "Não foi possivel listar as Categorias do PMS", "=(");
            });
        },
        async getTotalProdutosPMS() {
          try {
            const { data } = await this.$http(`pdv/getProdutosPMSTotal/${this.idpdvpms}/${this.idcategoriapms}/0?filtro=${this.produtoPesquisaDb}`)
            this.totalItems = data.total
          } catch (error) {
            this.$alertar("warning", "Não foi possivel listar o total de Produtos do PMS", "=(");
          }
        },
        async getProdutosPMS(pagina) {
          console.log('chegou aqui')
          console.log('pagina', pagina)
            pagina = pagina || 0
            this.currentPage = pagina + 1;
            this.listaProdutosPMS = [];
            this.listaProdutosPMSFiltrados = [];
            this.$http(`pdv/getProdutosPMS/${this.idpdvpms}/${this.idcategoriapms}/0?page=${this.currentPage}&pageSize=${this.itemsPerPage}&filtro=${this.produtoPesquisaDb}`)
                .then((resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.listaProdutosPMS = resp.data;
                    this.listaProdutosPMSFiltrados = this.listaProdutosPMS;
                    this.$emit("atualizarListaItensPdv", this.listaProdutosPMSFiltrados);
                }
            })
                .catch(() => {
                this.$alertar("warning", "Não foi possivel listar os Produtos do PMS", "=(");
            });
        },
    },
};
</script>

<style scoped>
.ddpms {
  /* background-color: white; */
  width: 35%;
  height: 100% !important;
}

.ddpmsprod {
}

.select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/

  background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif)
    no-repeat #fff; /* Imagem de fundo (Seta) */
  background-position: 98% center; /*Posição da imagem do background*/

  border: 1px solid black;
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding: 10px;
  padding-right: 20%;
  border-radius: 10px;
}
</style>
